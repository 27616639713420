
* {
  text-align: center;
  background-color: #fff;
  color: #03002e;
  /*color: #050444;*/
  padding: 0;
  margin: 0;

  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

label {
  cursor:inherit;
}

.content-wrapper {
  padding: 0 300px;
}

.navbar {
  height: 90px;
  width: 100%;
  display: grid;
  grid-template-columns: 300px 1fr;

  & a {
    text-decoration: none;
  }

  .navbar-logo {
    padding-top: 10px;
    text-align: left;

    img {
      width: 250px;
      cursor: pointer;
    }
  }

  .navbar-links {
    font-size: 18px;
    padding: 0 0 0 200px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);

    div {
      /*background: aqua;*/
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 35px;

      .link {
        background: none;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .explore-apps-outer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      width: 200px;
    }

    .explore-apps {
      border: #03002e 1px solid;
      border-radius: 4px;
      padding: 6px 10px;
      width: 155px;

      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }

      img {
        margin-left: 5px;
        height: 25px;
        cursor: pointer;
      }

      p {
        cursor: pointer;
      }
    }
  }
}

.landing {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .heading {
    font-size: 40px;
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    margin-bottom: 5px;
    color: #050444;
  }

  .heading-2 {
    margin-top: 10px;
    font-size: 20px;
  }
}

.central-content {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  div {
    height: 200px;
    /*width: 400px;*/
    background-color: #050444;
    border-radius: 6px;
    flex-grow: 1;
    padding: 10px 10px 20px 10px;

    display: grid;
    grid-template-rows: 40px 1fr;

    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    p {
      background: none;
      color: white;
      font-family: "century-gothic", sans-serif;
      font-weight: 400;
      font-style: normal;
    }

    .box-heading {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .box-content {
      font-size: 14px;

      & a {
        background: none;
        color: white;
        font-family: "century-gothic", sans-serif;
      }
    }
  }
}

.were-starting {
  border: 1px solid #050444;
  border-radius: 10px;
  width: 100%;
  height: 50vh;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .starting-header {
    font-family: "century-gothic", sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
  }

  .starting-paragraph {
    font-size: 20px;
  }

  & .join-newsletter {
    margin-top: 10px;
    font-size: 20px;

    &:hover {
      opacity: 0.6;
    }
  }
}

.eula {
  line-height: 1.4;
}

.blog-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 200px;

  .bold {
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 25px;
  }

  .underline {
    text-decoration: underline;
  }

  ol {
    list-style-type: decimal;
    margin-left: 18px;
    font-size: 20px;

    & li {
      text-align: left;
    }
  }

  ul {
    margin-left: 18px;
    font-size: 20px;

    & li {
      text-align: left;
    }
  }

  .list-privacy {
    margin-left: 23px;
  }

  .list-nested {
    list-style-type: lower-alpha;
    margin-left: 22px;
  }

  .list-nested-privacy {
    margin-left: 22px;
  }

  & .blog-header-wrapper {
    height: 100px;
    margin-top: 150px;
  }

  & .privacy-header-wrapper {
    height: 60px;
    margin-top: 150px;
  }

  & .privacy-header-wrapper-website {
    height: 60px;
    margin-top: 30px;
  }

  & .blog-header {
    font-size: 35px;
  }

  & .blog-content-wrapper {
    margin-bottom: 120px;

    & p {
      text-align: left;
      font-size: 20px;
    }

    & .about-us {
      margin: 25px 0;
    }

    & .contact-method {
      margin: 5px 0;
    }

    & .margin-top {
      margin-top: 20px;
    }
  }

  & .blog-content-wrapper-privacy-apps {
    margin-bottom: 50px;
  }
}

.lets-accelerate-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 130px;

  & .header {
    font-size: 25px;
    font-family: "century-gothic", sans-serif;
    margin-bottom: 5px;
  }

  & .join-newsletter {
    font-size: 18px;
    font-family: "century-gothic", sans-serif;
  }
}

.footer {
  background-color: #03002e;
  height: 600px;
  padding: 0 300px;
  display: grid;
  grid-template-rows: 1fr 35px;

  div {
    background-color: #03002e;
    color: #fff;
  }

  .footer-content {
    display: grid;
    grid-template-columns: 400px repeat(3, 1fr);
    margin-bottom: 100px;

    .footer-logo {
      display: grid;
      grid-template-rows: 100px 140px 1fr;

      .footer-logo-logo {
        margin-top: 50px;
        text-align: left;

        img {
          width: 250px;
        }
      }

      .footer-logo-address {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 30px;

        p {
          background: none;
          color: #fff;
        }

        .city {
          font-size: 20px;
          margin-bottom: 10px;
        }

        .street {
          font-size: 15px;
        }
      }

      .linkedin-logo {
        text-align: left;

        a {
          background-color: #03002e;
        }

        img {
          background-color: #03002e;
          height: 30px;
          cursor: pointer;
        }
      }
    }

    .footer-header {
      background: none;
      color: white;
      margin: 70px 0 10px 0;
      font-size: 23px;
    }

    .link {
      background: none;
      cursor: pointer;
      color: white;
      margin-bottom: 5px;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.mobile-navbar-wrapper {
  display: none;
}

.privacy-paragraph {
  margin-top: 25px;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .mobile-navbar-wrapper {
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
  }

  .mobile-menu {
    height: 140px;
    width: 200px;
    padding-top: 20px;
    border: 1px solid #03002e;
    border-radius: 10px;
    position: fixed;
    top: 60px;
    right: 10px;
    display: none;

    & p {
      margin-bottom: 15px;
    }
  }

  .mobile-menu-active {
    display: block;
  }

  .content-wrapper {
    padding: 0 10px;
  }

  /* MOBILE - BLOG-CONTAINER */
  .blog-container {
    padding: 0 0;

    .bold {
      margin-top: 20px;
    }

    & .blog-header-wrapper {
      height: 50px;
      margin-top: 30px;
    }

    & .blog-header {
      font-size: 25px;
    }

    & .blog-content-wrapper {
      margin-bottom: 50px;

      & p {
        text-align: left;
        font-size: 15px;
      }

      & .philosophy-paragraph {
        margin: 25px 0;
      }

      & .about-us {
        margin: 25px 0;
      }

      & .contact-method {
        margin: 5px 0;
      }

      & .margin-top {
        margin-top: 20px;
      }
    }
  }

  /* MOBILE - NAVBAR */
  .navbar {
    height: 90px;
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1fr;

    .navbar-logo {
      padding-top: 10px;
      text-align: left;

      img {
        width: 150px;
        cursor: pointer;
      }
    }

    .navbar-links {
      display: none;
    }
  }

  .lets-accelerate-wrapper {
    & .header {
      font-size: 20px;
    }

    & .join-newsletter {
      font-size: 15px;
    }
  }

  /* MOBILE - FOOTER */
  .footer {
    height: auto;
    padding: 0 0;
    background-color: #03002e;
    display: grid;
    grid-template-rows: 1fr 25px;

    div {
      background-color: #03002e;
      color: #fff;
    }

    .footer-content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 200px repeat(3, 1fr);

      .footer-logo {
        display: grid;
        grid-template-rows: 60px 90px 1fr;
        margin-left: 10px;

        .footer-logo-logo {
          margin-top: 20px;
          text-align: left;

          img {
            width: 150px;
          }
        }

        .footer-logo-address {
          padding-top: 10px;

          .city {
            font-size: 15px;
            margin-bottom: 0px;
          }

          .street {
            font-size: 13px;
          }
        }

        .linkedin-logo {
          img {
            height: 20px;
          }
        }
      }

      .footer-header {
        background: none;
        color: white;
        margin: 30px 0 10px 0;
        font-size: 23px;
      }
    }

    .copyright {
      font-size: 13px;
    }
  }

  .landing {
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .heading {
      font-size: 20px;
      font-family: "century-gothic", sans-serif;
      font-weight: 400;
      font-style: normal;
      text-align: left;
      margin-bottom: 5px;
      color: #050444;
    }

    .heading-2 {
      margin-top: 10px;
      font-size: 15px;
    }
  }

  .central-content {
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    div {
      height: 200px;
      background-color: #050444;
      border-radius: 6px;
      flex-grow: 1;
      padding: 10px 10px 20px 10px;

      display: grid;
      grid-template-rows: 40px 1fr;

      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      p {
        background: none;
        color: white;
        font-family: "century-gothic", sans-serif;
        font-weight: 400;
        font-style: normal;
      }

      .box-heading {
        font-size: 18px;
        margin-bottom: 15px;
      }

      .box-content {
        font-size: 14px;
      }
    }
  }

  .were-starting {
    .starting-header {
      font-family: "century-gothic", sans-serif;
      font-size: 23px;
      margin-bottom: 10px;
    }

    .starting-paragraph {
      font-size: 18px;
    }

    & .join-newsletter {
      margin-top: 10px;
      font-size: 18px;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
